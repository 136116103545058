import { forwardRef } from 'react';

import { Box, CircularProgress, InputAdornment, SxProps, Theme, useTheme } from '@mui/material';

import { Search as SearchIcon } from 'react-feather';
import TextField from '~/components/atoms/TextField';
import TextFieldProps from '~/components/atoms/TextField/types';

import { AButton } from '@allvit/allvit-toolkit';
import { mergeSx } from '~/utils/mui';
import { Link, useLocation } from '@remix-run/react';

interface SearchInputProps extends TextFieldProps {
    inputState: 'loading' | 'idle' | 'submitting';
    size?: 'small' | 'medium' | 'large';
    onReset?: () => void;
    onEnterButton?: {
        handler: () => void;
        label?: string;
    };
    value?: string;
    wrapperSx?: SxProps<Theme>;
}

const SearchInput = forwardRef<unknown, SearchInputProps>(
    ({ onChange, value, inputState, size, onKeyUp, onReset, onEnterButton, wrapperSx, sx, inputProps }, ref) => {
        const theme = useTheme();
        const location = useLocation();

        return (
            <Box sx={mergeSx({ position: 'relative' }, wrapperSx)}>
                <TextField
                    ref={ref}
                    onKeyUp={onKeyUp}
                    inputProps={{
                        'aria-label': 'Søketekst',
                        id: 'searchInput',
                        'data-cy': 'cy-search-input',
                        ...inputProps,
                    }}
                    onChange={onChange}
                    size={size}
                    fullWidth
                    value={value}
                    sx={mergeSx(
                        {
                            zIndex: 0,
                            pl: size === 'large' ? 6 : 4,
                        },
                        sx,
                    )}
                    startAdornment={
                        <InputAdornment position="start" sx={{ mr: size === 'large' ? 4 : 3 }}>
                            <SearchIcon
                                color={
                                    inputProps && inputProps.disabled
                                        ? theme.palette.text.disabled
                                        : theme.palette.mode === 'light'
                                          ? theme.palette.cta.main
                                          : theme.palette.common.white
                                }
                                size={size === 'large' ? 26 : size === 'medium' ? 24 : 20}
                            />
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            {inputState !== 'idle' ? (
                                <CircularProgress
                                    size={size === 'large' ? 22 : size === 'small' ? 16 : 18}
                                    sx={{
                                        color:
                                            theme.palette.mode === 'light'
                                                ? theme.palette.cta.main
                                                : theme.palette.common.white,
                                    }}
                                />
                            ) : (
                                <>
                                    {onReset && inputState === 'idle' && value && value.length > 0 && (
                                        <AButton
                                            variant="transparent"
                                            onClick={onReset}
                                            data-cy="cy-search-nullstill"
                                            size={size || 'small'}
                                        >
                                            Nullstill
                                        </AButton>
                                    )}
                                    {onEnterButton && inputState === 'idle' && value && value.length > 0 && (
                                        <AButton
                                            component={Link}
                                            state={{ from: location.pathname }}
                                            variant="primary"
                                            sx={{
                                                '&.MuiButton-sizeLarge': {
                                                    fontSize:
                                                        size === 'large' ? theme.typography.h5.fontSize : 'inherit',
                                                },
                                            }}
                                            onClick={onEnterButton.handler}
                                            data-cy="cy-search-enter"
                                            size={size || 'small'}
                                        >
                                            {onEnterButton.label ?? 'Søk'}
                                        </AButton>
                                    )}
                                </>
                            )}
                        </InputAdornment>
                    }
                />
            </Box>
        );
    },
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
